core = core || {};

//.elenco = risorse.elenco || {};
//risorse.elenco = $.extend(risorse.elenco,tmp);
core.message = {};

/*
 * @message  {String or DOMElement} The notification message contents.
 * @type     {String }              The Type of notification message (CSS class name 'ajs-{type}' to be added).
 * @wait     {Number}               The time (in seconds) to wait before the notification is auto-dismissed.
 * @callback {Function}             A callback function to be invoked when the notification is dismissed.
 * 
 * @return {Object} Notification object.
 *
 * alertify.notify(message, type, wait, callback)
 *
 */
core.message.notify = function(message,type,options){
    //alertify.dismissAll();
    // .ajs-message.ajs-custom { color: #31708f;  background-color: #d9edf7;  border-color: #31708f; }
    
   
    //Obbligatorio altrimenti duplica , è un bug
    alertify.dismissAll();
    var notifyClass = (type) ? "notify-"+type : 'notify-info';
    if(options && options.class){
        notifyClass = options.class;
    }
    
    var position = (options && options.position) ? options.position : 'bottom-center';
    alertify.set('notifier','position', position);
    alertify.notify(message, notifyClass, 3, function(){console.log('dismissed');});
};


core.message.error = function(message,options){
    //alertify.dismissAll();
    // .ajs-message.ajs-custom { color: #31708f;  background-color: #d9edf7;  border-color: #31708f; }
    
    /*alertify.set('notifier','position', position);*/
    //alertify.notify(message, notifyClass, 3, function(){console.log('dismissed');});
    
    let _options = options || {};    
    core.message.alert(message,'error',_options)

};

core.message.success = function(message,options){
    let _options = options || {};    
    core.message.alert(message,'success',_options)
};


core.message.alert = function(message,type,options){
    alertify.dismissAll();
    let notifyClass = "notify-info";
    let position = "bottom-center";

    let icon = '';
    let title = '';
    switch(type){
        case 'error':
             notifyClass = "notify-error";
             position =  'top-center';
             title = "<div class='clearfix text-center'><h4>ATTENZIONE<h4></div>";
             icon = "<div class='clearfix text-center'><i class='ajs-icon la la-exclamation-circle text-danger' style='font-size:60px;' ></i></div>";
        break;
        case 'success':
             notifyClass = "notify-error";
             icon = "<div class='clearfix text-center'><i class='ajs-icon la la-check-circle text-success' style='font-size:60px;' ></i></div>";
        break;
        case 'warning':
             icon = "<div class='clearfix text-center'><i class='ajs-icon la la-exclamation-circle text-warning '  ></i></div>";
        break;
        default:
        break;
    }

    options.position = position;
    options.class = notifyClass;

    let _callback = options.callback || null;
    alertify.alert(title, icon + "<div class='clearfix text-center'>"+message+"</div>",function(){
        if($.isFunction(_callback)){
                _callback.call(this);
        }
    }).set({transition:'fade'});

    //.dialog('confirm').set({transition:'pulse',message: 'Transition effect: pulse'}).show(); 


  
  


    //oppure
    //alertify.alert().set('message', 'This is a new message!').show(true, 'custom-class'); 

    //.set('modal', true); 
    //.set('movable', false); 
    //.set('basic', true);
    //.set('closable', false); 
    //.set('label', 'Alright!');  //bottone testo
    //.set('maximizable', true);  
    //.set('padding',false); 
};

core.message.confirm = function(message,options){
        var _callbackOK = options.callback || null;
        var _callbackKO = options.callbackKO || null;
        var _title = options.title || 'Warning';
        var _message = message;

        alertify.confirm(
        	_title, 
        	_message, 
        	function(){ if($.isFunction(_callbackOK)){ _callbackOK.call(this);} },
            function(){ }
        );

};


core.message.prompt = function(title,message,options){
            alertify.prompt( 'Prompt Title', 'Prompt Message', 'Prompt Value'
               , function(evt, value) { alertify.success('You entered: ' + value) }
               , function() { alertify.error('Cancel') });
};





