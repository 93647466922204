//Da provare
// jQuery on an empty object, we are going to use this as our queue
//
//
core = core || {};

//POST
core.post = function (url, data, options) {
  axios.defaults.headers.common["X-Requested-With"] = "xmlhttprequest";
  axios.defaults.headers.common["X-CSRF-TOKEN"] = $(
    'meta[property="_xtk"]'
  ).attr("content");

  let config = {};
  let token = null;
  if (options.clearcache) {
    //Turbolinks.clearCache();
  }

  if (options.cancelToken) {
    token = options.cancelToken;
  } else {
    let cancelToken = axios.CancelToken;
    let source = cancelToken.source();
    token = source.token;
  }

  //let cancelToken = null;

  data = data || {};
  let _options = options || {};

  //Create a serialized representation of an array, a plain object,
  data = $.param(data);

  /*config = {
    	params : data,
    	cancelToken : options.cancelToken || null
    }*/

  xhr = axios({
    method: "post",
    url: "" + url,
    data: data,
    cancelToken: token,
  })
    .then((res) => {
      _options.response = res.data;
      core.parseAjaxResponse(res, _options);
    })
    .catch((error) => {
      if (error && error.response) {
        let res = error.response;
        let xhr = res.request;
        core.parseAjaxResponse(xhr, _options);
      }
    });

  return xhr;
};

core.get = function () {};

//da aggiungere delete

//Da aggiungere Patch

core.parseAjaxResponse = function (xhr, opts = {}) {
  status = xhr.status;

  let callbackOK = opts && opts.callbackOK ? opts.callbackOK : null;
  let callbackKO = opts && opts.callbackKO ? opts.callbackKO : null;
  console.log("STATUS " + status);

  if (status == 200) {
    let response = opts.response || null;
    let urlRedir = (response && response.redirect) || null;
    let message = (response && response.message) || "";

    if (callbackOK && jQuery.isFunction(callbackOK)) {
      callbackOK.call(null, response);
    } else {
      setTimeout(function () {
        core.preloader.hide();
      }, 300);

      if (message && message.length > 0) {
        core.message.success(message);
      }

      if (urlRedir && urlRedir.length > 0) {
        setTimeout(() => {
          core.redirect(urlRedir);
        }, 300);
      }
    }
  } else {
    core.preloader.hide();
    if (xhr.responseText) {
      try {
        //Run some code here
        //r = jQuery.parseJSON(jQuery.trim(response));
        let response = JSON.parse(jQuery.trim(xhr.responseText));

        let error = response.error;
        let message = error.message || null;
        let urlRedir = error.redirect || null;

        /*if(message && message.length > 0){
					core.message.error(message);	
				}

				if(urlRedir && urlRedir.length > 0){
					setTimeout(() => { core.redirect(urlRedir)}, 300);
				}*/

        if (callbackKO && jQuery.isFunction(callbackKO)) {
          callbackKO.call(null, response);
        } else {
          setTimeout(function () {
            core.preloader.hide();

            if (message && message.length > 0) {
              core.message.error(message);
            }

            if (urlRedir && urlRedir.length > 0) {
              setTimeout(() => {
                core.redirect(urlRedir);
              }, 300);
            }
          }, 300);
        }
      } catch (err) {
        //Errore parsing risposta
      }
    }
  }
};

core.redirect = function (url) {
  if (Turbolinks.supported) {
    Turbolinks.visit(url, { action: "restore" }); //{ action: 'restore' }
  } else {
    window.location.href = url;
  }
};
