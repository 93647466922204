/* timeout utilizzato per l'annullamento dell'eventuale hidePreload */



core.preloader = {};
core.preloader.PRELOADER_TIMEOUT = null;

core.preloader.show = function(message){

    clearTimeout(core.preloader.PRELOADER_TIMEOUT);

    var message = message || '';

    var $preloader = jQuery('.page-preloader-overlay');

    var preloadMessage = ''; //Loading
    if(message !== undefined && message.length > 0){
       //jQuery('.page-preloader-message').html(message);
        preloadMessage = message;
    }

    jQuery('.page-preloader-message').html(preloadMessage);

    //$preloader.addClass('animated slideInDown');
    $preloader.show();

};


core.preloader.hide = function(callback){
  
    var $preloader = jQuery('.page-preloader-overlay');
    
    core.preloader.PRELOADER_TIMEOUT = setTimeout(function(){
         //core.utils.bodyShowScrollbars();

         $preloader.fadeOut('fast');
    },400);
};
