/*!
 * historyStack
 * Gestisge lo stack di navigazione  tra le pagine , usando HTML5 session storage
 * 
 * © 2017 MR
 */



//.elenco = risorse.elenco || {};
//risorse.elenco = $.extend(risorse.elenco,tmp);
core.form = {};


/**
 *
 * FORM INIT
 *
 */

core.form.init = function(container) {

    jQuery('label.checkbox, label.radio').each(function() {
        var $_checkbox = jQuery(':input', this);
        var _helper = $_checkbox.next('.helper');
        console.log(_helper);

        if (_helper == undefined || _helper.length == 0) {
            _helper = jQuery("<span class='helper'/>");
            $_checkbox.after(_helper);
        }
    });



    //PREFISSI TELEFONICI
    jQuery('input.phonenumber').intlTelInput({
        separateDialCode: true,
        initialCountry: "it",
        autoHideDialCode: false
            //,hiddenInput: _hidden
    });

    /*jQuery("form.validate").each(function(){
       // core.form.validate(this)
    })*/
    //INPUTMASK
    Inputmask.extendDefinitions({
        'pn': {
            validator: function(chrs, buffer, pos, strict, opts) {
                alert(chrs);
                return new RegExp("[0-9]+").test(chrs);
            },
            cardinality: 1
        }
    });
    //im.mask( jQuery(".phonenumber",container));



    jQuery(":input.integer", container).inputmask("integer", { rightAlign: true, "showMaskOnFocus": false, "showMaskOnHover": false, "clearIncomplete": false, "clearMaskOnLostFocus": false, "removeMaskOnSubmit": false, "greedy": false, "placeholder": "" });
    jQuery(":input.numeric", container).inputmask("decimal", { rightAlign: true, "showMaskOnFocus": false, "negative": false, "showMaskOnHover": false, "clearIncomplete": false, "clearMaskOnLostFocus": false, "removeMaskOnSubmit": false, "greedy": false, "placeholder": "" });
    /*
    jQuery(".date",container).inputmask("date", {yearrange: {minyear: 1700, maxyear: 3099}, "showMaskOnFocus": false, "showMaskOnHover": false, "removeMaskOnSubmit": false, "clearIncomplete": true, "placeholder": "__/__/____"});
    jQuery(".datetime",container).inputmask("d/m/y[ h:s]", {yearrange: {minyear: 1700, maxyear: 3099}, "showMaskOnFocus": false, "showMaskOnHover": false, "removeMaskOnSubmit": false, "clearIncomplete": false, "greedy": false});
    jQuery(".time",container).inputmask("h:s", {rightAlign:false, "showMaskOnFocus": false, "showMaskOnHover": false, "removeMaskOnSubmit": false, "clearIncomplete": true, "greedy": false,"placeholder":'__:__'});
    jQuery(".time-no-picker",container).inputmask("h:s", {rightAlign:false, "showMaskOnFocus": false, "showMaskOnHover": false, "removeMaskOnSubmit": false, "clearIncomplete": true, "greedy": false,"placeholder":'__:__'});
    
    jQuery(".cap").inputmask("9{5}",{"showMaskOnFocus": false, "showMaskOnHover": false, "clearIncomplete": true, "clearMaskOnLostFocus": true, "removeMaskOnSubmit": true, "greedy": false, "placeholder": ""});
    jQuery(".numerical").inputmask("9{0,}",{"showMaskOnFocus": false, "showMaskOnHover": false, "clearIncomplete": false, "clearMaskOnLostFocus": false, "removeMaskOnSubmit": false, "greedy": false, "placeholder": ""});
    */
    jQuery(":input.currency", container).inputmask("currency", {
        radixPoint: ",",
        groupSeparator: "",
        digits: 2,
        greedy: false,
        autoGroup: true,
        clearIncomplete: false,
        showMaskOnHover: false,
        showMaskOnFocus: false,
        clearMaskOnLostFocus: false,
        removeMaskOnSubmit: false,
        prefix: '',
        placeholder: "",
        rightAlign: true
    });


    jQuery(":input.uppercase", container).inputmask({
        mask: "*{1,}",
        placeholder: "",
        greedy: false,
        showMaskOnHover: false,
        showMaskOnFocus: false,
        definitions: {
            '*': {
                validator: "[0-9,a-zA-Z'\\\\ \\(\\)\\.\\-/]",
                cardinality: 1,
                casing: "upper"
            }
        },
        onBeforePaste: function(pastedValue, opts) {
            pastedValue = pastedValue.toUpperCase();
            return pastedValue;
        }
    });

    jQuery(":input.phonenumber", container).inputmask({
        mask: "*{1,}",
        placeholder: "",
        greedy: false,
        showMaskOnHover: false,
        showMaskOnFocus: false,
        definitions: {
            '*': {
                validator: "[0-9]+",
                cardinality: 1
            }
        },
    });


    /*
        Serve per i i fieldset da mostrare al click dei radio....
    */
    jQuery("[data-toggle]").on('click change', function() {

        let toggle = jQuery(this).data('toggle');
        let toggleList = jQuery(this).data('toggle').split(',');
        let toggleGroup = jQuery(this).data('toggle-group');
        let toggleGroupList = jQuery(this).data('toggle-group') ? jQuery(this).data('toggle-group').split(',') : new Array();

        jQuery(toggleGroupList).each(function(index) {
            let el = toggleGroupList[index];
            _hideSection(el);
        })

        jQuery(toggleList).each(function(index) {
            let el = toggleList[index];
            _showSection(el);
        })
    });




    //======================================================
    // COMPORTAMENTI DI DEFAULT
    // es. se ha selezionato country IT allora in visualizzazione mi si apriranno determinati fieldset
    //======================================================       

    jQuery(":checkbox:checked").not(":disabled").trigger('click');
    jQuery(":radio:checked").not(":disabled").trigger('click');


    jQuery('select:visible').not(':disabled').each(function() {
        if (jQuery(this).val() !== '' || jQuery(this).val() != 0) {
            jQuery(this).trigger('change');
            jQuery(this).trigger('click');
        }
    });


}


//Lancia la validazione sulle form
//nell'init vengolo validate le form con classe validate
core.form.validate = function(_form, opts) {
    var opts = opts || {}
    var _ignore = opts.ignore || null;

    return jQuery(_form).validate({
        ignore: _ignore,
        focusInvalid: true,
        scrollToInvalid: true,




        /*errorPlacement: function(error, element){
              var $validationBlock = jQuery(element).next('.validation-block');
              if($validationBlock.length == 0){
                  $validationBlock = jQuery("<ul class='validation-block'/>");
                    jQuery(element).after($validationBlock);
                    
                }
              error.appendTo($validationBlock);
        },*/
        //oneByOne: false,
        /*liveValidation: true,
        focusInvalid: true,
        scrollToInvalid:false, // fa lo scroll
        ignoreTitle: true,*/
        //wrapper: 'li',
        //errorElement_: "li.validation-error", //label
        //errorClass: "validation-error",
        //errorElement: 'span',


        //onkeyup: false,
        wrapper: 'li',
        errorPlacement: function(error, element) {

                var $validationBlock = jQuery(element).next('.validation-block');

                //Ho creato io un array
                var placement = '';
                var message = '';

                var name = jQuery(element).attr('name');

                if ($.validation) {

                    if ($.validation.hasOwnProperty(name)) {

                        var v = $.validation[name];
                        if (v.hasOwnProperty("placement")) {
                            placement = v.placement;
                        }
                        if (v.hasOwnProperty("message")) {
                            message = v.message;
                        }
                    }
                } else {
                    placement = jQuery(element).data('validation-placement') || '';
                    message = jQuery(element).data('validation-message') || '';
                }

                message = (message.length > 0) ? message : error.text();

                if ($validationBlock.length == 0) {

                    if (jQuery(placement).length > 0) {

                        if (placement == 'alert') {


                            core.message.alert('error', message);


                            return false;
                        } else {


                            $validationBlock = jQuery("<ul class='validation-block' />");
                            $validationBlock.appendTo(jQuery(placement));

                        }

                    } else {
                        $validationBlock = jQuery("<ul class='validation-block' />");
                        if (jQuery(element).parents('.input-group').length > 0) {
                            jQuery(element).parents('.input-group:eq(0)').after($validationBlock);
                        } else {
                            jQuery(element).after($validationBlock);
                        }

                    }




                }



                if (message.length > 0) {
                    //error = jQuery("<li class='validation-error' />").html(message);
                    error.appendTo($validationBlock); //assegno agli ul l'id del nome del campo
                    //jQuery(element).after(error);
                } else {
                    //$validationBlock.hide();
                }



            }
            /*,
                                        success: function(form) {
                                            alert('form valida procedo al submit');
                                            form.submit();
                                            return false;
                                        }*/
    });



};


/**
 * Funzione per l'invio normale della form
 * @param  {[type]} _form     [description]
 * @param  {[type]} _callback [description]
 * @return {[type]}           [description]
 */
core.form.submit = function(_form, options) {
    var _form = _form || jQuery(this).parents('form:eq(0)');
    var _validator = core.form.validate(_form);
    if (_form.length == 0) {
        alert('Form not defined');
        return false;
    }
    if (jQuery(_form).valid()) {

        //invio la form con submit 
        jQuery(_form).submit();
    } else {
        _validator.focusInvalid();
    }
}


/**
 * Funzione per l'invio via ajax della form
 * @param  {[type]} _form     [description]
 * @param  {[type]} _callback [description]
 * @return {[type]}           [description]
 */
core.form.ajaxSubmit = function(_form, options) {


    var options = options || {};
    var _data = options.params || {};
    var _form = _form || jQuery(this).parents('form:eq(0)');

    var _url = options.url || jQuery(_form).attr('action');

    var _callbackOK = options.callbackOK || null;
    var _callbackKO = options.callbackKO || null;
    var _beforeSubmit = options.beforeSubmit || null;
    var _flgPreload = options.preload || false;
    //var _target = options.target || null;

    if (jQuery(_form).length == 0) {
        return false;
    }


    if (options.clearCache && Turbolinks.supported) {
        //Turbolinks.clearCache();
    }


    //Sezione da validare
    var _section = options.section || _form;

    var validateOptions = {};

    if (options.section) {
        var ignore = new Array();
        jQuery(":input", _form).filter(function(index) {
            console.log(jQuery(this).parents(jQuery(_section)).length);
            var parent = jQuery(this).parents(_section).get(0);
            if (jQuery(parent).length == 0) {
                console.log(jQuery(this).attr('name'));
                ignore.push(this);
            }
            return true
        });

        if (ignore.length > 0) {
            validateOptions.ignore = ignore;
        }

    }

    var validator = core.form.validate(_form, validateOptions);

    if (jQuery(_form).valid()) { //jQuery(_form).valid()



        if (_flgPreload) {
            core.preloader.show();
        }

        //invio la form con submit o via ajax
        //jQuery(_form).submit();

        if (!_url || _url.length == 0) {
            alert('Url not specified');
            core.preloader.hide();
            return false;
        }

        var _options = {
            //target:        '#output2',   // target element(s) to be updated with server response 
            data: _data,
            url: _url,
            iframe: false, //(URL_SEGMENTS.hasOwnProperty('view') && URL_SEGMENTS.view == 'modal') ? true : false,
            beforeSubmit: _beforeSubmit,
            //timeout:   6000,
            success: function(response, textStatus, xhr) {

                //faccio il parse dell'ajax response come sempre????
                //core.form.submit(_form); //per ora cosi', altrimenti faccio la solita elaborazione parseAjaxRepsonse
                console.log('parseResponse');
                console.log(xhr);
                console.log(response);
                let opts = options;
                opts.response = response;
                opts.callbackOK = _callbackOK;
                opts.elem = null;

                core.parseAjaxResponse(xhr, opts);

                //core.parseAjaxResponse(opts); 

            },
            error: function(xhr, exception, message) {

                let opts = options;
                console.log("Exceptions ajaxSubmit");
                console.log(xhr);
                //var response = {};
                //response.message = 

                opts.callbackKO = _callbackKO;
                opts.elem = null;

                core.parseAjaxResponse(xhr, opts);
                /*
                console.log(xhr);        
                //Nascondo il preload
                var message = '';
                var statusErrorMap = {
                    '400' : "Errore 400 - Server understood the request, but request content was invalid",
                    '401' : "Errore 401 - Unauthorized access",
                    '403' : "Errore 403 - Forbidden resource can't be accessed",
                    '500' : "Errore 500 - Internal server error",
                    '503' : "Errore 503 - Service unavailable"
                };

                if (xhr.status) {
                    message =statusErrorMap[xhr.status];

                    if(!message){
                          message = ''; //message="Unknown Error \n";
                      }
                }else if(exception=='parsererror'){
                    message="Error.\nParsing JSON Request failed";
                }else if(exception=='timeout'){
                    message="Request Time out";
                }else if(exception=='abort'){
                    //message="Request was aborted by the server";
                }
                    
                if(message.length > 0){
                    alert(message);
                }
                */


                return false;
            }
        };

        jQuery(_form).ajaxSubmit(_options);

    } else {
        core.preloader.hide();
        validator.focusInvalid();
    }

    return false;


}


//core.search = $.extend(core.search,tmp);


_showSection = function(el) {
    let section = jQuery(el);
    jQuery("input,:checkbox,:radio,select,textarea", section).prop("disabled", false);
    jQuery(section).removeClass('d-none');
};


_hideSection = function(el) {
    let section = jQuery(el);
    jQuery("input,:checkbox,:radio,select,textarea", section).prop("disabled", true);
    jQuery(section).addClass('d-none');
};