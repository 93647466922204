/*
Vendors
*/

//Normalize
window.core = window.core || {};
window.app = window.app || {};

require('normalize.css/normalize.css');
require('./css/reset.css');

//require('./themeCrown/css/external.css'); //DI cui viene fatto l'verride

//Turbolinks
var Turbolinks = require("turbolinks");

Turbolinks.start();
window.progressBar = new Turbolinks.ProgressBar();
Turbolinks.setProgressBarDelay(5);

//jQuery
window.$ = window.jQuery = require('jquery');

window.ProgressBarJs = require('progressbar.js') //per preloaders..

window.Cookies = require('js-cookie');


//Bootstrap
window.Popper = require('popper.js').default; // pay attention to "default"
require('bootstrap/dist/js/bootstrap');
import 'bootstrap/dist/css/bootstrap.css';

//Axios
window.axios = require('axios');

//Animate.css
require('animate.css');

//jQuery UI
require('./vendors/jquery-ui-1.12.1.custom/jquery-ui.css');
require('./vendors/jquery-ui-1.12.1.custom/jquery-ui.js');

//jQuery Validation 
require('./js/jquery-validation-master/src/core.js');
require('./js/jquery-validation-master/src/localization/messages_it.js');



//Moment
require('./vendors/moment.js');

//Animate.css
//require('./vendors/animate.css');

//Checkbox
require('./css/checkbox.css');



//Cookie Bar da sostituire con quantcast
require('./vendors/jquery.cookiebar/jquery.cookiebar.css');
require('./vendors/jquery.cookiebar/jquery.cookiebar.js');




//JQUERY WATCH 
require('./js/jquery-watch/jquery-watch.js');

//Inputmask
//var Inputmask = require('inputmask');
require('./vendors/Inputmask-4.x/dist/jquery.inputmask.bundle.js');


//CORE Form
require('./js/core/core.form.js');
require('./vendors/jquery.form.js');

//Intl-tel-input
require('./vendors/intl-tel-input-master/build/css/intlTelInput.css');
require('./vendors/intl-tel-input-master/build/css/intlTelInput-override.css');
require('./vendors/intl-tel-input-master/build/js/intlTelInput.js');
require('./vendors/intl-tel-input-master/build/js/utils.js');


//Alertify
window.alertify = require('alertifyjs');
//require('./vendors/alertifyjs/alertify.js');
require('./vendors/alertifyjs/css/alertify.css');
require('./vendors/alertifyjs/css/themes/default.css');
/*require('./vendors/alertifyjs/css/themes/bootstrap.css');*/
require('./css/alertify-override.css');
require('./js/core/core.message.js');

//Fonts
require('./fonts/fontawesome-free-5.13.0-web/css/all.css');
require('./fonts/line-awesome/1.3.0/css/line-awesome.css');
require('./fonts/kmsicuro-collection/font/flaticon.css');

//CUSTOM che fa qualcosina di override anche su bootstrap
require('./themeCrown/css/style.css'); //DI cui viene fatto l'verride
require('./css/bootstrap-override.css');
require('./css/fonts.css');
require('./css/form.css');
require('./css/shared.css');
require('./css/table.css');
require('./css/navbar.css');
require('./css/layout.css');


require('./js/core/functions.js');
//CORE Ajax
require('./js/core/core.ajax.js');
//CORE Preloader
require('./js/core/core.preloader.js');

require('./js/core/core.utils.js');

//INIT
require('./js/core/core.init.js');






/*
axios.get("https://jsonplaceholder.typicode.com/users").then((res) => {
		console.log(res);
})
*/