//lo carico qui, in modo da rimuovere il prleoader una volta fatto
GOOGLE_MAPS_KEY = "AIzaSyBJ70wd7r7qGO7Vx_WppHlbSPfNJlTDmz4";
GOOGLE_MAP_SCRIPT_URL =
  "https://maps.googleapis.com/maps/api/js?key=" + GOOGLE_MAPS_KEY; //&callback=googlePlacesInit&libraries=places
GOOGLE_MAPS_CALLBACK = new Array(); //CPda utilizzata per accodare tutte le funzioni. Chiamato dalla init di google places
GOOGLE_MAPS_COUNTRY = "IT";
GOOGLE_MAPS_LOADED = false;
SKEY = "Kms312d8a";

window.core = window.core || {};
window.app = window.app || {};

jQuery(function ($) {
  core.init();

  $(window).resize(function () {
    //Altrimenti il fblike non appare
    try {
      FB.XFBML.parse();
    } catch (ex) {}
  });

  //safari hack
  if (!window.location.hash && window.addEventListener) {
    window.addEventListener("orientationchange", function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 0);
    });
    /*window.addEventListener( "touchstart",function() {
              setTimeout(function(){
                  window.scrollTo(0, 0);
              }, 0);
          });*/
  }

  $(document).on("turbolinks:load", function () {
    console.log("tb:load");
    core.init();
    //progressBar.setValue(1);
    //setTimeout(function(){progressBar.hide();},200);
    //alert('load');
  });

  $(document).on("turbolinks:before-visit", function () {
    //progressBar.setValue(0.3);
    //progressBar.show();
    //alert('before visit');
    //Turbolinks.clearCache();
    //alert('clear index');
    console.log("tb:befvisit");
  });

  $(document).on("turbolinks:click", function () {
    //progressBar.setValue(0.3);
    //progressBar.show();
    //alert('click');
    console.log("tb:click");
  });

  $(document).on("turbolinks:request-start", function () {
    console.log("tb:req start");
  });
  $(document).on("turbolinks:request-end", function () {
    console.log("tb:req end");
  });
});

core.init = function () {
  console.log("init");

  if (!Turbolinks.supported) {
    //Redirect update browser
  }

  let random = core.utils.randomString(6);
  let xtk = core.utils.MD5(random + SKEY);

  $('meta[property="_xtk"]').attr("content", xtk);

  $.ajaxSetup({
    headers: {
      "X-CSRF-Token": $('meta[property="_xtk"]').attr("content"),
      "X-CAKE": random,
    },
  });

  axios.defaults.headers.common["X-Requested-With"] = "xmlhttprequest";
  axios.defaults.headers.common["X-CSRF-TOKEN"] = $(
    'meta[property="_xtk"]'
  ).attr("content");
  axios.defaults.headers.common["X-CAKE"] = random;

  //Previene il click degli href disabilitati
  $("a.disabled").on("click", function (event) {
    event.preventDefault();
  });

  //Tooltip
  $('[data-toggle="tooltip"]').tooltip();

  //Scroll all'elemento se aggiungo data-scroll=scrollTo posso anche scrivere scroollTo-65
  $("a[data-scroll^='scrollTo'][href^='#']").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    let offset = 0;
    let attr = jQuery(this).attr("data-scroll");
    let regex = /scrollTo-([0-9]+)/i;
    let regexResult = regex.exec(attr);
    if (regexResult && regexResult.length == 2) {
      offset = regexResult[1];
    }

    let href = jQuery(this).attr("href"); //.replace("#",'');
    if ($(href).length > 0) {
      $("html,body").animate(
        {
          scrollTop: $(href).offset().top - offset,
        },
        500
      );
    }

    return false;
  });

  //Scroll se ho un window hash
  if (window.location.hash) {
    let offset = 0;
    let href = window.location.hash;
    if ($(href).length > 0) {
      setTimeout(function () {
        $("html,body").animate(
          {
            scrollTop: $(href).offset().top - offset,
          },
          500
        );
      }, 100);

      history.replaceState(null, null, " ");
    }
  }

  //Nasconde le scrollbar del body alla visualizzazione della modale
  $(".modal")
    .on("shown", function () {
      $("body").css("overflow", "hidden");
    })
    .on("hidden", function () {
      $("body").css("overflow", "auto");
    });

  core.form.init();

  core.navbar.init();

  //Importante, fa il refresh di Facebook like
  try {
    FB.XFBML.parse();
  } catch (ex) {}

  /*
     //Da spostare poi dove serve
     let url = GOOGLE_MAP_SCRIPT_URL; 



     if (typeof google === 'object' && typeof google.maps === 'object') {

                 setTimeout(() => {
                          jQuery('.filters-location').removeClass('loading');
                          //$('input[name=location]').focus();
                 },600);

     }else{

         url += '&callback=googlePlacesInit&libraries=places';

         $.ajax({
                 //async: true,
                 url: url,
                 dataType: "script",
                 beforeSend: function(){
                     //core.showPreload();
                 },
                 success: function(){
                     GOOGLE_MAPS_LOADED = true;
                     setTimeout(function(){
                          jQuery('.filters-location').removeClass('loading');
                          //$('input[name=location]').focus();
                     },600);
                 },
                 error: function(){
                     //invio Mail e notifico l'errore
                     //alert('Errore caricamento Città');
                 }
             });

     }*/

  //A che serviva?????????  serve per i i fieldset da mostrare al click dei radio....

  if (!jQuery("body").hasClass("kms-app")) {
    $.cookieBar({
      fixed: true,
      bottom: true,
      message:
        "<div class='col-md-10' style='float:left;text-align:left;'>Utilizziamo i cookie affinchè tu possa avere la migliore esperienza sul nostro sito. Chiudendo questo banner o proseguendo la navigazione in altra maniera, acconsenti all’uso dei cookie e confermi di aver preso visione e di accettare la nostra <a href='./privacy_policy' target='_blank'>privacy policy</a></div>",
      acceptText: "Accetto",
      //,forceShow: true
    });
  }

  //RImuove la toolbar safari
  setTimeout(function () {
    window.scrollTo(0, 1);
  }, 0);

  //-------------------------------
  //Tawk.to
  //-------------------------------
  window.$_Tawk = undefined;
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  if (!jQuery("body").hasClass("kms-app")) {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/5f46627d1e7ade5df444203d/default";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");

    //jQuery(s1).appendTo("head");
    s0.parentNode.insertBefore(s1, s0);
  }
  // Code using $ as usual goes here; the actual jQuery object is jq2
};

/* aggiungo le funzioni da collegare ala callback del caricamento google places */
//Va messo sulle funzioni che effettivamente mi richiedono l'esecuzione di codice al caricamento
GOOGLE_MAPS_CALLBACK.push(function () {
  //alert('Google maps loaded');
});

/**
 * Callback del caricamento di google map che inizializza google places
 * @return {[type]} [description]
 */

window.googleMapsInit = function () {
  /*map = new google.maps.Map(document.getElementById('map'), {
       center: {lat: -34.397, lng: 150.644},
       zoom: 8
     });*/

  GOOGLE_MAPS_LOADED = true;

  jQuery.each(GOOGLE_MAPS_CALLBACK, function (index, value) {
    if ($.isFunction(GOOGLE_MAPS_CALLBACK[index])) {
      GOOGLE_MAPS_CALLBACK[index].call();
    }
  });
};

/* FACEBOOK SHARE */
/*
 jQuery(".btn-share-fb").on('click', function(event) {
     event.preventDefault();
     var that = jQuery(this);
     var post = that.parents('article.post-area');
     jQuery.ajaxSetup({ cache: true });
        
         FB.ui({
           method: 'share_open_graph',
           action_type: 'og.shares',
           display: 'popup',
           action_properties: JSON.stringify({
             object: {
               'og:url': 'https://www.kmsicuro.it'
               //'og:title': 'Prova 123',
               //'og:description': 'Test descrizione',
               //'og:image': 'https://www.kmsicuro.it/assets/images/fb_page_header@2x.jpg'
             }
           })
           },
           function(response) {
             if (response && !response.error_code) {
                 if(ACTION == 'CONVERSION'){
                     var userType = jQuery("input[name=user_type]:checked").val();
                     if(userType == 1){
                         sendCouponExtra();
                         alert("Grazie, ti abbiamo inviato per email, lo sconto extra");
                     }
                 }else{
                      alert("Grazie, per la tua condivisione!");
                 }
                 
                 $('#modalContactSuccess').modal('hide');
                 $('#modalConvertSuccess').modal('hide');
                 
             } else {
                     if(ACTION == 'CONVERSION'){
                         var userType = jQuery("input[name=user_type]:checked").val();
                    
                         if(userType == 1){
                             alert("Non condividendo e chiudendo questa finestra non avrai più diritto allo sconto extra. \n\rNon perdere questa opportunità e condividi se ti piace KMSICURO");
                         }else{
                              alert("Condividi per far sapere ai tuoi clienti che la trasparenza e la lealtà sono i valori della tua azienda!.");
                         }
                     }
             }
         });
   //});
   
 });
 */

//VERIFICA .IT
var url = window.location.href;
if (url.indexOf("kmsicuro.com") != -1) {
  window.location.href = "https://www.kmsicuro.it";
}

core.navbar = {};
core.navbar.init = function () {
  //AGGIUNGE NAVBAR-FIXED-TOP
  //Aggiunge il fixed Top dopo un determinato Offset
  if (
    $("#navbarMain[data-navbar-fixed-top]").length > 0 &&
    !$("#navbarMain").hasClass("navbar-fixed-top")
  ) {
    let scrollStartOffset = $("#navbarMain").data("navbar-fixed-top");
    $(document).scroll(function () {
      let _st = $(window).scrollTop();

      if (_st > scrollStartOffset) {
        jQuery("#navbarMain[data-navbar-fixed-top]").addClass(
          "navbar-fixed-top"
        );
      } else {
        jQuery("#navbarMain[data-navbar-fixed-top]").removeClass(
          "navbar-fixed-top"
        );
      }
    });
  }

  //AGGIUNGE TOGGLER-ACTIVE AL NAVBAR-TOGGLER
  //Aggiunge o rimuove la clkasse active al toggler
  $(".navbar-toggler", "#navbarMain").on("click", function (e) {
    if (jQuery(".navbar-collapse", "#navbarMain").is(":visible")) {
      $(".navbar-toggler", "#navbarMain").removeClass("toggler-active");
    } else {
      $(".navbar-toggler", "#navbarMain").addClass("toggler-active");
    }
  });

  //CHIUDE LA NAVBARMAIN AL CARICAMENTO
  //Rimaneva aperta con turbolinks cache
  $(".navbar-collapse", "#navbarMain")
    .removeClass("collapsing")
    .addClass("collapse");

  //NASCONDE LA NAVBAR AL CLICK DELLE VOCI DI MENU
  $("a", ".navbar-collapse").on("click", function () {
    if (jQuery(".navbar-collapse.show", "#navbarMain").is(":visible")) {
      $(".navbar-toggler", "#navbarMain").removeClass("toggler-active");
      $(".navbar-collapse", "#navbarMain").collapse("hide");
    }
  });

  //NASCONDE LA NAVBAR AL CLICK DEL BODY
  $("body").on("click", function () {
    if (jQuery(".navbar-collapse.show", "#navbarMain").is(":visible")) {
      $(".navbar-toggler", "#navbarMain").removeClass("toggler-active");
      $(".navbar-collapse", "#navbarMain").collapse("hide");
    }
  });

  //Verifica lo scroll e aggiunge lo shadow alla navbar
  let st = $(window).scrollTop();
  if (st > 100) {
    $("#navbarMain.navbar-fixed-top").addClass("navbar-shadow");
  } else {
    $("#navbarMain").removeClass("navbar-shadow");
  }
  $(document).scroll(function () {
    let _st = $(window).scrollTop();
    if (_st > 100) {
      $("#navbarMain.navbar-fixed-top").addClass("navbar-shadow");
    } else {
      $("#navbarMain").removeClass("navbar-shadow");
    }
  });
};
