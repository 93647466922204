
/**
 * Recupera i parametri passati in url dopo ? 
 * e ritorna un array
 * @returns {Array}
 */
getUrlParams = function() {
    url = window.location.href;
    //url = url.toLowerCase();
    var tmp = url.split('?');
    if(tmp.length > 1){
        return parseUrlParams(tmp[1]);
    }
    return new Array();
    /*name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));*/
}


/**
 * Recupera persando i parametri in get a partire dalla query string
 * @param {type} qs
 * @returns {unresolved}
 */
function parseUrlParams(qs) {
    qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}




goback = function(){
  if(history && history.length > 0){
      window.history.back();
  }else{
    redirect('index');
  }
}
redirect = function(url){
	if(Turbolinks.supported){
        
		Turbolinks.visit(url,{ action: 'restore' });
	}else{
		window.location.href = url;
	}
}


/**
    Condivide su facebook
*/
shareFacebook = function(options){

    let _options = options || {};


    //var that = jQuery(this);
    //var post = that.parents('article.post-area');
    jQuery.ajaxSetup({ cache: true });
    /*jQuery.getScript('//connect.facebook.net/en_US/sdk.js', function(){
    FB.init({
      appId: '912999508714473',
      version: 'v6.0' // or v2.0, v2.1, v2.0 
    });*/
    FB.ui({
      method: 'share_open_graph',
      action_type: 'og.shares',
      display: 'popup',
      action_properties: JSON.stringify({
        object: {
          'og:url': 'https://www.kmsicuro.it'
          //'og:title': 'Prova 123',
          //'og:description': 'Test descrizione',
          //'og:image': 'https://www.kmsicuro.it/assets/images/fb_page_header@2x.jpg'
        }
      })
      },
      function(response) {
        if (response && !response.error_code) {
            //sendCouponExtra();

            //alert("Grazie, ti abbiamo inviato per email, lo sconto extra");
            //$('.modal').modal('hide');
        } else {
          //alert("Non condividendo e chiudendo questa finestra non avrai più diritto allo sconto extra. \n\rNon perdere questa opportunità e condividi se ti piace KMSICURO");
        }
        //$('.modal').modal('hide');
    });
}

scrollToElement = function(el,offset){
  let _offset = offset || 0;
   $('html,body').animate({
      scrollTop: jQuery(el).offset().top - _offset
  },500);
}
/*
$.fn.showSection = function(){
    var _this = this;
    var section =  jQuery(this);
    jQuery("input,:checkbox,:radio,select,textarea",section).prop("disabled",false);
    $(section).removeClass('d-none');
};


$.fn.hideSection = function(){
    var _this = this;
    var section =  jQuery(this);
    jQuery("input,:checkbox,:radio,select,textarea",section).prop("disabled",true);
    $(section).addClass('d-none');
};*/